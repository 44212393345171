import React, { useState, useEffect, Fragment } from 'react';
import {
  NonAuthRoutes,
  AuthRoutes,
  TrackableContainersRoutes,
} from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import { Box, CSButton, Typography } from '../primitives/index';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import styles from './header.styles';
import { withStyles } from '@material-ui/core/styles';
import dateUtils from '../../services/utils/date';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import moment from 'moment';

// Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RestoreIcon from '@material-ui/icons/Restore';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// Services
import WeatherService, {
  WeatherData,
} from '../../services/Weather.service';
import LocalStorageService from '../../services/LocalStorage.service';
import SystemService from '../../services/System.service';
import Auth from '../../services/Auth.service';
import PermissionsService from '../../services/Permissions.service';

// Utils
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import {
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
} from 'cloudsort-client';
import CSHeaderLogo from './logo/CSLogo';
import { useAppDispatch } from '../../redux/store';
import { setSelectedEntity } from '../../redux/slices/navigationSlice';
import { SELECTOR_ENTITY_TYPE } from '../navigation/types';
import { CSDatepicker } from '../primitives/csDatepicker';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import EventIcon from '@material-ui/icons/Event';

interface Props {
  classes: { [key: string]: string };
  type?: string;
  navActive: boolean;
  setNavActive: (active: boolean) => void;
}

const Header: React.FC<Props> = ({
  classes,
  type = 'default',
  navActive,
  setNavActive,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const [weatherAnchorEl, setWeatherAnchorEl] =
    useState<null | HTMLElement>(null);
  const [isDatepickerOpen, setIsDatepickerOpen] =
    useState<boolean>(false);
  const [currentTime, setCurrentTime] = React.useState<String>();
  const [weatherData, setWeatherData] = useState<WeatherData | null>({
    icon: 'na',
  });

  const dispatch = useAppDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isWeatherOpen = Boolean(weatherAnchorEl);

  const [showResetDBDialog, setShowResetDBDialog] =
    useState<boolean>(false);

  const userFullName = LocalStorageService.getMyFullName();

  const getWeather = async () => {
    const station = LocalStorageService.getMyStationData();
    try {
      if (station && station.zipcode) {
        const data = await WeatherService.getWeatherForStation(
          station.zipcode,
        );
        setWeatherData(data);
      } else {
        //try again in 2s, station data is not set yet
        setTimeout(getWeather, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleWeatherOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setWeatherAnchorEl(event.currentTarget);
  };

  const handleWeatherClose = () => {
    setWeatherAnchorEl(null);
  };

  const resetDB = async () => {
    if (process.env.REACT_APP_ENV !== 'production') {
      setShowProgress(true);
      try {
        await SystemService.resetDB();
        await globalStationOptionsUtils.setStationData();
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const menuId = 'primary-search-account-menu';

  const isTrackableContainersHeader = type === 'trackableContainers';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      disableScrollLock={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {PermissionsService.hasPermission(
        StaffPermissionPermissionsEnum.STATION_USER_SELF_READ,
      ) && (
        <MenuItem
          onClick={() => {
            browserHistory.push(AuthRoutes.PROFILE);
          }}
          id='header-link-profile'
        >
          My CloudSort profile
        </MenuItem>
      )}
      {PermissionsService.hasPermission(
        StaffPermissionPermissionsEnum.WEBHOOK_READ,
      ) &&
        PermissionsService.belongsToAnyOfRoles([
          StaffPermissionRoleTypeEnum.ORG_ADMIN,
          StaffPermissionRoleTypeEnum.SUPER_USER,
        ]) && (
          <MenuItem
            onClick={() => {
              browserHistory.push(AuthRoutes.WEBHOOKS);
            }}
            id='header-link-webhooks'
          >
            Webhooks
          </MenuItem>
        )}

      {PermissionsService.hasPermission(
        StaffPermissionPermissionsEnum.WEBHOOK_READ,
      ) &&
        PermissionsService.belongsToAnyOfRoles([
          StaffPermissionRoleTypeEnum.SUPER_USER,
        ]) && (
          <MenuItem
            onClick={() => {
              browserHistory.push(TrackableContainersRoutes.MAP);
            }}
            id='header-link-trackable-containers'
          >
            Trackable Containers
          </MenuItem>
        )}

      {process.env.REACT_APP_ENV !== 'production' &&
        (window.location.host.includes('staging') ||
          (window.location.host.includes('demo') &&
            PermissionsService.belongsToAnyOfRoles([
              StaffPermissionRoleTypeEnum.SUPER_USER,
            ])) ||
          window.location.host.includes('preview') ||
          window.location.host.includes('development') ||
          window.location.host.includes('localhost')) &&
        !isTrackableContainersHeader && [
          <MenuItem
            key='header-tracking-page'
            id='header-link-tracking-page'
            onClick={(e) => {
              e.preventDefault();
              browserHistory.push(NonAuthRoutes.TRACKING);
            }}
          >
            Tracking Page
          </MenuItem>,
          <MenuItem
            key='header-reset-db'
            id='header-link-reset-db'
            onClick={(e) => {
              e.preventDefault();
              handleMenuClose();
              setShowResetDBDialog(true);
            }}
          >
            Reset DB
          </MenuItem>,
        ]}
      <MenuItem
        data-testid={'header-logout'}
        id='header-link-logout'
        onClick={(e) => {
          e.preventDefault();
          Auth.logOut({}).then(() => {
            dispatch(
              setSelectedEntity({
                id: 0,
                type: SELECTOR_ENTITY_TYPE.STATION,
              }),
            );
            browserHistory.push(NonAuthRoutes.LOGIN);
          });
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={handleProfileMenuOpen}
        id='header-link-account'
      >
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <Typography variant='body1' component='p'>
          {userFullName || 'Account'}
        </Typography>
      </MenuItem>
    </Menu>
  );

  const weatherTooltipId = 'weather-tooltip';
  const renderWeatherTooltip = (
    <Menu
      anchorEl={weatherAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={weatherTooltipId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isWeatherOpen}
      onClose={handleWeatherClose}
    >
      {!!weatherData && (
        <div className={classes.weatherTooltip}>
          <img
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/header/weather-icons/${weatherData.icon}.png`}
            alt='Weather'
            width='48'
            height='auto'
          />
          <Typography variant='h5' color='tertiary'>
            {weatherData.title}
          </Typography>
          <Typography variant='body2'>
            {weatherData.temperature} &deg;F
          </Typography>
          {weatherData.cityID && (
            <CSButton
              className={classes.weatherMoreInfo}
              variant='contained'
              color='secondary'
              target='_blank'
              rel='noopener noreferrer'
              href={`https://openweathermap.org/city/${weatherData.cityID}`}
            >
              More Info
            </CSButton>
          )}
        </div>
      )}
      {!weatherData && (
        <div className={classes.weatherTooltip}>
          <h3>There is no weather information at the moment.</h3>
        </div>
      )}
    </Menu>
  );

  const getGMT = () => {
    let hour = new Date().getUTCHours();
    let minutes: number | string = new Date().getUTCMinutes();
    const middayConfig = hour >= 12 ? 'PM' : 'AM';

    hour = hour > 12 ? hour - 12 : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `GMT: ${
      hour.toString().length === 2 ? '' : '0'
    }${hour}:${minutes} ${middayConfig}`;
  };

  const getCurrentTime = () => {
    return new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  useEffect(() => {
    setCurrentTime(getCurrentTime());
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    getWeather();

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      {showProgress && <ProgressIndicator />}
      <Box className={classes.mainBox}>
        <div className={classes.grow}>
          <AppBar position='static' className={classes.appbar}>
            <Toolbar disableGutters className={classes.toolbar}>
              <Box className={classes.secondaryBox}>
                <IconButton
                  onClick={() => {
                    setNavActive(!navActive);
                  }}
                  className={classes.headerMenuToggle}
                >
                  {navActive ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <Box className={classes.logoContainer}>
                  <Link
                    to={
                      isTrackableContainersHeader
                        ? TrackableContainersRoutes.MAP
                        : AuthRoutes.DASHBOARD
                    }
                  >
                    <CSHeaderLogo
                      width='185'
                      alt='CloudSort'
                      className={classes.headerLogo}
                    />
                  </Link>
                </Box>
              </Box>
              <div className={classes.sectionDesktop}>
                <CSDatepicker
                  selectionType='date'
                  id='date-picker-header'
                  variant='dialog'
                  isOpen={isDatepickerOpen}
                  initialValue={LocalStorageService.getSystemDate()}
                  onClose={() => {
                    setIsDatepickerOpen(false);
                  }}
                  onAcceptSelection={(date) => {
                    if (date) {
                      if (moment(date).isSame(new Date(), 'date')) {
                        LocalStorageService.removeSystemDate();
                      } else {
                        LocalStorageService.setSystemDate(
                          date as Date,
                        );
                      }
                      let urlQueryParams: string[] = [];
                      if (window.location.href.includes('?'))
                        urlQueryParams = window.location.href
                          .split(/[?#]/)[1]
                          .split('&');

                      // Reset page url query param to 1 on date change
                      if (
                        urlQueryParams.filter((el) =>
                          el.includes('page='),
                        ).length
                      ) {
                        urlQueryParams = urlQueryParams.map(
                          (param: string) => {
                            if (param.includes('page=')) {
                              return 'page=1';
                            }
                            return param;
                          },
                        );

                        window.history.replaceState(
                          null,
                          '',
                          `?${urlQueryParams.join('&')}`,
                        );
                      }

                      window.location.reload();
                    }
                  }}
                >
                  <div
                    className={classes.timeHolder}
                    id='header-link-current-time'
                    onClick={(
                      event: React.MouseEvent<HTMLElement>,
                    ) => {
                      PermissionsService.hasPermission(
                        StaffPermissionPermissionsEnum.STATION_REPORT_READ,
                      ) && setIsDatepickerOpen(true);
                    }}
                  >
                    {dateUtils.isSystemDateToday() ? (
                      <Fragment>
                        <Typography
                          variant='overline'
                          component='p'
                          color='primary'
                          className={classes.GMTTime}
                        >
                          {getGMT()}
                        </Typography>
                        <Typography
                          variant='h6'
                          component='p'
                          className={classes.currentTime}
                        >
                          {currentTime}
                        </Typography>
                        <Typography
                          variant='caption'
                          component='p'
                          color={{ color: 'grey', variant: 'A400' }}
                          className={classes.currentDate}
                        >
                          {new Date().toDateString()}
                        </Typography>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <IconButton className={classes.notToday}>
                          <RestoreIcon />
                        </IconButton>
                        <Typography
                          variant='caption'
                          component='p'
                          color={{ color: 'grey', variant: 'A400' }}
                          className={classes.currentDate}
                        >
                          {new Date(
                            LocalStorageService.getSystemDate(),
                          ).toDateString()}
                        </Typography>
                      </Fragment>
                    )}
                  </div>
                </CSDatepicker>
                {type === 'default' &&
                  !isTrackableContainersHeader && (
                    <IconButton
                      aria-label='Weather Info'
                      aria-controls={weatherTooltipId}
                      aria-haspopup='true'
                      onClick={handleWeatherOpen}
                      color='inherit'
                      className={
                        classes.borderLeft +
                        ' ' +
                        classes.paddingButton
                      }
                    >
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME || ''
                        }/header/weather-icons/${
                          weatherData?.icon
                        }.png`}
                        alt='Search'
                        width='24'
                        height='auto'
                      />
                    </IconButton>
                  )}
                {(type === 'default' ||
                  isTrackableContainersHeader) && (
                  <MenuItem
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    color='inherit'
                    className={
                      classes.borderLeft + ' ' + classes.paddingMenu
                    }
                    data-testid={'header-menu'}
                  >
                    <div className={classes.accountNameInitials}>
                      {userFullName &&
                        userFullName.split(' ')[0].charAt(0) +
                          userFullName
                            .split(' ')
                            [
                              userFullName.split(' ').length - 1
                            ].charAt(0)}
                    </div>
                    <Typography variant='body1' component='p'>
                      {userFullName || 'Account'}
                    </Typography>
                    <MoreVertIcon />
                  </MenuItem>
                )}
                {type === 'profile' && (
                  <MenuItem
                    onClick={() => {
                      browserHistory.push(AuthRoutes.DASHBOARD);
                    }}
                    color='inherit'
                    className={
                      classes.borderLeft +
                      ' ' +
                      classes.paddingMenu +
                      ' ' +
                      classes.exitProfileButton
                    }
                    data-testid={'header-close-profile'}
                  >
                    Exit CloudSort Profile <CloseIcon />
                  </MenuItem>
                )}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label='More...'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          {renderWeatherTooltip}
          <Hidden mdUp>
            <Paper
              variant='outlined'
              square
              onClick={() => {
                if (
                  PermissionsService.hasPermission(
                    StaffPermissionPermissionsEnum.STATION_REPORT_READ,
                  )
                ) {
                  setIsDatepickerOpen(true);
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                p={0.5}
              >
                <EventIcon />
                <Typography
                  variant='body2'
                  component='p'
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {new Date(
                    LocalStorageService.getSystemDate(),
                  ).toDateString()}
                </Typography>
              </Box>
            </Paper>
          </Hidden>
        </div>
        <ConfirmationDialog
          data-testid={'reset-db-dialog'}
          title={'Reset Database'}
          msg={`Are you sure you want to reset the database?`}
          primaryActionLabel={'Reset'}
          onPrimaryAction={() => {
            resetDB();
          }}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowResetDBDialog(false);
          }}
          isOpen={showResetDBDialog}
        />
      </Box>
    </Fragment>
  );
};

export default withStyles(styles, { name: 'CSHeader' })(Header);
