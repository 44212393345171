import {
  Grid,
  withStyles,
  createStyles,
  Theme,
  Paper,
  GridSize,
  Snackbar,
  IconButton,
  Box,
} from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { containerPingLocationPointLayer } from './layers';
import { Map, Source, Layer, MapRef } from 'react-map-gl';

import type { FeatureCollection } from 'geojson';
import { initialMapViewWholeUS, mapColors } from './constants';
import TrackableContainersLayout from '../trackableContainersLayout/TrackableContainersLayout';
import TrackableContainersService from '../../services/TrackableContainers.service';
import { CheckPoint, TrackableContainer } from 'cloudsort-client';
import {
  interpolateColor,
  renderBatteryPercentageLabel,
  renderTemperatureLabel,
  renderLocalTimestamp,
  renderLocalTimezone,
} from './utils';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import { TrackableContainersRoutes } from '../../interfaces/routes';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Typography } from '../primitives';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MapboxService from '../../services/Mapbox.service';
import clsx from 'clsx';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AxiosError } from 'axios';
import ErrorHandler from '../../utils/ErrorHandler';
import { Helmet } from 'react-helmet';
import moment from 'moment';

export interface DetailsRouterProps {
  asset_id: string;
  id?: string;
}
interface Props extends RouteComponentProps<DetailsRouterProps> {
  classes: { [key: string]: string };
}

// enum SELECTED_TIMEFRAME {
//   ALL = 'ALL',
//   TODAY = 'TODAY',
//   YESTERDAY = 'YESTERDAY',
//   THIS_WEEK = 'THIS_WEEK',
//   LAST_WEEK = 'LAST_WEEK',
// }

const TrackableContainersDetails = ({ match, classes }: Props) => {
  const assetId = useMemo(
    () => match.params.asset_id,
    [match.params.asset_id],
  );
  const containerId = useMemo(() => match.params.id, [match.params]);
  const mapRef = useRef<MapRef>(null);

  const [container, setContainer] = useState<TrackableContainer>();
  const [pingsHistory, setPingsHistory] = useState<
    CheckPoint[] | undefined
  >([]);
  const [cityName, setCityName] = useState<string>('');
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // const [selectedPingsTimeframe, setSelectedPingsTimeframe] =
  //   useState<SELECTED_TIMEFRAME>(SELECTED_TIMEFRAME.ALL);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const getAndSetContainerData = async (id: number) => {
    setShowProgress(true);
    try {
      //fetch details data
      const details = (await TrackableContainersService.getById(id))
        .data;

      setContainer(details);

      //fetch pings history data
      const history = (
        await TrackableContainersService.getPingHistoryById(id)
      ).data.trackable_container_history;

      setPingsHistory(
        history?.sort((a, b) => {
          return (
            Number(new Date(b.timestamp)) -
            Number(new Date(a.timestamp))
          );
        }),
      );
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const fetchData = async () => {
    if (containerId) {
      getAndSetContainerData(Number(containerId));
    } else if (assetId) {
      const listResult = (
        await TrackableContainersService.getAll({
          page: 1,
          pageSize: 1,
          assetId,
        })
      ).data.results;

      if (listResult.length === 1 && listResult[0].id) {
        getAndSetContainerData(listResult[0].id);
      } else {
        setError(`Trackable container ${assetId} was not found`);
      }
    }
  };

  const fetchCityName = async () => {
    if (
      container?.tracker_device &&
      container.tracker_device.last_location_lat &&
      container.tracker_device.last_location_long
    ) {
      try {
        const res = await MapboxService.getCeocodingData({
          search: `${container?.tracker_device?.last_location_long},${container?.tracker_device?.last_location_lat}`,
          type: ['place'],
        });
        if (res.data.features.length > 0) {
          setCityName(res.data.features[0].place_name);
        }
      } catch (e) {
        handleError(e as AxiosError);
      }
    }
  };

  useEffect(() => {
    fetchCityName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    setBoundsOnPingsHistoryChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pingsHistory]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBoundsOnPingsHistoryChange = () => {
    if (mapRef.current && pingsHistory?.length) {
      const longitudes = pingsHistory.map((ping) => ping.lng);
      const latitudes = pingsHistory.map((ping) => ping.lat);

      mapRef.current.fitBounds(
        [
          [Math.min(...longitudes), Math.min(...latitudes)], //sw
          [Math.max(...longitudes), Math.max(...latitudes)], //ne
        ],
        { padding: 50, maxZoom: 12 },
      );
    } else {
      // Try again in 2s
      setTimeout(() => {
        setBoundsOnPingsHistoryChange();
      }, 2000);
    }
  };

  const geojson: FeatureCollection | undefined = useMemo(() => {
    if (!pingsHistory || pingsHistory?.length === 0) return undefined;

    return {
      type: 'FeatureCollection',
      features: pingsHistory.map((ping, index) => {
        return {
          type: 'Feature',
          properties: {
            borderWidth: index === pingsHistory.length - 1 ? 5 : 0, //last node should have border
            color:
              '#' +
              interpolateColor(
                mapColors.gradient.start,
                mapColors.gradient.end,
                (index + 1) / pingsHistory.length,
              ),
          },
          geometry: {
            type: 'Point',
            coordinates: [ping.lng, ping.lat],
          },
        };
      }),
    };
  }, [pingsHistory]);

  const commonColumnSingleDetail = {
    labelColumns: 3 as GridSize,
    valueColumns: 8 as GridSize,
  };

  return (
    <TrackableContainersLayout maxWidth='lg'>
      <Helmet>
        <title>
          {`CloudSort - Trackable Container Details for ${
            container?.identifier || ''
          }`}
        </title>
      </Helmet>
      {showProgress && <ProgressIndicator />}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showCopySnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowCopySnackbar(false);
        }}
        message='	GPS Coordinates copied to clipboard'
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setShowCopySnackbar(false);
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.marginTop20}>
          {error && (
            <CSDialogAlert bottomMargin={20} alertMessage={error} />
          )}
          {container && (
            <CSBreadcrumbs
              breadcrumbs={[
                {
                  label: 'Home',
                  link: TrackableContainersRoutes.MAP,
                },
                {
                  label: container?.identifier || 'N/A',
                  selected: true,
                },
              ]}
            />
          )}
        </Grid>
        {container && (
          <>
            <Grid item xs={12} className={classes.marginTop20}>
              <Typography variant='h3' component='h1'>
                Container ID: {container?.identifier}
              </Typography>
              <CSSectionTitleSeparator
                topMargin={10}
                bottomMargin={10}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Paper className={classes.paperStyle}>
                <CSSingleDetailMonoColumnContainer
                  header='Container Details'
                  elements={[
                    <SingleDetail
                      label='Battery Life'
                      value={renderBatteryPercentageLabel(
                        container.tracker_device,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Temperature'
                      value={renderTemperatureLabel(
                        container.tracker_device,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Paper className={classes.paperStyle}>
                <Grid container>
                  <Grid item>
                    <Box
                      className={clsx(
                        classes.iconBg,
                        classes.iconBgPrimary,
                      )}
                    >
                      <LocationOnOutlinedIcon
                        className={classes.highlightIcon}
                      />
                    </Box>
                  </Grid>
                  <Grid item className={classes.flexGrow}>
                    <Typography
                      variant='body2'
                      color={{ color: 'grey', variant: 'A400' }}
                    >
                      Current Location
                    </Typography>
                    <Typography
                      variant='h4'
                      className={classes.marginY5}
                    >
                      {cityName || 'N/A'}
                    </Typography>
                    <Typography
                      variant='body2'
                      color={{ color: 'grey', variant: 'A400' }}
                    >
                      {container?.tracker_device?.last_location_lat ||
                        'N/A'}
                      ,
                      {container?.tracker_device
                        ?.last_location_long || 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                className={clsx(
                  classes.paperStyle,
                  classes.marginTop20,
                )}
              >
                <Grid container>
                  <Grid item>
                    <Box
                      className={clsx(
                        classes.iconBg,
                        classes.iconBgSecondary,
                      )}
                    >
                      <BeenhereOutlinedIcon
                        className={classes.highlightIcon}
                      />
                    </Box>
                  </Grid>
                  <Grid item className={classes.flexGrow}>
                    <Typography
                      variant='body2'
                      color={{ color: 'grey', variant: 'A400' }}
                    >
                      Last Event
                    </Typography>
                    <Typography
                      variant='h4'
                      className={classes.marginY5}
                    >
                      {container.tracker_device.last_location_ts
                        ? `${renderLocalTimestamp(
                            container.tracker_device.last_location_ts,
                          )} ${renderLocalTimezone()}`
                        : 'N/A'}
                    </Typography>
                    <Typography
                      variant='body2'
                      color={{ color: 'grey', variant: 'A400' }}
                    >
                      {container.tracker_device.last_location_ts
                        ? moment(
                            container.tracker_device.last_location_ts,
                          ).fromNow()
                        : 'N/A minutes ago'}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' component='h1'>
                Container Journey
              </Typography>
              <CSSectionTitleSeparator
                topMargin={10}
                bottomMargin={10}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant='body2'>Time Range</Typography>
              <CSButton
                size='small'
                color='secondary'
                variant={
                  selectedPingsTimeframe === SELECTED_TIMEFRAME.ALL
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setSelectedPingsTimeframe(SELECTED_TIMEFRAME.ALL);
                }}
                className={classes.timerangeButtonMargin}
              >
                All
              </CSButton>
              <CSButton
                size='small'
                color='secondary'
                variant={
                  selectedPingsTimeframe === SELECTED_TIMEFRAME.TODAY
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setSelectedPingsTimeframe(SELECTED_TIMEFRAME.TODAY);
                }}
                className={classes.timerangeButtonMargin}
              >
                Today
              </CSButton>
              <CSButton
                size='small'
                color='secondary'
                variant={
                  selectedPingsTimeframe ===
                  SELECTED_TIMEFRAME.YESTERDAY
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setSelectedPingsTimeframe(
                    SELECTED_TIMEFRAME.YESTERDAY,
                  );
                }}
                className={classes.timerangeButtonMargin}
              >
                Yesterday
              </CSButton>
              <CSButton
                size='small'
                color='secondary'
                variant={
                  selectedPingsTimeframe ===
                  SELECTED_TIMEFRAME.THIS_WEEK
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setSelectedPingsTimeframe(
                    SELECTED_TIMEFRAME.THIS_WEEK,
                  );
                }}
                className={classes.timerangeButtonMargin}
              >
                This Week
              </CSButton>
              <CSButton
                size='small'
                color='secondary'
                variant={
                  selectedPingsTimeframe ===
                  SELECTED_TIMEFRAME.LAST_WEEK
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setSelectedPingsTimeframe(
                    SELECTED_TIMEFRAME.LAST_WEEK,
                  );
                }}
                className={classes.timerangeButtonMargin}
              >
                Last Week
              </CSButton>
            </Grid> */}
            {(pingsHistory || []).length > 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.pingsContainer}
                >
                  <PaginatedTable
                    title='Ping History'
                    showPagination={true}
                    columns={[
                      {
                        id: 'id',
                        label: 'ID',
                        hide: true,
                      },
                      {
                        id: 'time',
                        label: 'Time',
                      },
                      {
                        id: 'coordinates',
                        label: 'GPS Coordinates',
                      },
                    ]}
                    sortableBy={['time']}
                    actions={[
                      {
                        cellWidth: 20,
                        tableLabel: ' ',
                        columnLabel: <FileCopyOutlinedIcon />,
                        callbackProperty: 'coordinates',
                        qualifier: 'coordinates',
                        callback: (coordinates: string) => {
                          navigator.clipboard.writeText(coordinates);
                          setShowCopySnackbar(true);
                        },
                      },
                    ]}
                    fetch={(page, rowsPerPage) => {
                      const tz = renderLocalTimezone();

                      const slicedDataset = pingsHistory?.slice(
                        (page - 1) * rowsPerPage,
                        page * rowsPerPage,
                      );

                      return {
                        data: {
                          count: pingsHistory?.length,
                          results: slicedDataset?.map(
                            (ping, index) => {
                              return {
                                index: index + 1,
                                time:
                                  renderLocalTimestamp(
                                    ping.timestamp,
                                  ) +
                                  ' ' +
                                  tz,
                                coordinates: `${ping.lat.toFixed(
                                  6,
                                )}, ${ping.lng.toFixed(6)}`,
                              };
                            },
                          ),
                        },
                      };
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.relativeContainer}
                >
                  <Box className={classes.legend}>
                    <Box className={classes.legendBar} />
                    <Box className={classes.legendBarText}>
                      <Typography variant='body1'>Past</Typography>
                      <Typography
                        variant='body1'
                        className={classes.alignRight}
                      >
                        Current
                      </Typography>
                    </Box>
                  </Box>

                  <Map
                    reuseMaps
                    mapboxAccessToken={
                      process.env.REACT_APP_MAPBOX_API_KEY || ''
                    }
                    mapLib={import('mapbox-gl')}
                    initialViewState={
                      container?.tracker_device?.last_location_lat &&
                      container?.tracker_device?.last_location_long
                        ? {
                            longitude:
                              container.tracker_device
                                .last_location_long || 0,
                            latitude:
                              container.tracker_device
                                .last_location_lat || 0,
                            zoom: 10,
                          }
                        : initialMapViewWholeUS
                    }
                    minZoom={1}
                    style={{ width: '100%', height: '500px' }}
                    mapStyle={
                      process.env.REACT_APP_MAPBOX_STYLE || ''
                    }
                    ref={mapRef}
                  >
                    <Source
                      id='containers'
                      type='geojson'
                      data={geojson}
                    >
                      <Layer {...containerPingLocationPointLayer} />
                    </Source>
                  </Map>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </TrackableContainersLayout>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    marginTop20: {
      marginTop: 20,
    },
    marginY5: {
      margin: '5px 0',
    },
    paperStyle: {
      padding: 16,
      boxShadow: theme.shadows[2],
    },
    flexGrow: {
      flexGrow: 1,
    },
    timerangeButtonMargin: {
      margin: '10px 10px 0 0',
      padding: '3px 9px',
    },
    pingsContainer: {
      [theme.breakpoints.up('sm')]: {
        maxHeight: 512,
        overflowY: 'scroll',
      },
    },
    highlightIcon: {
      color: theme.palette.common.white,
      width: 24,
      height: 'auto',
    },
    iconBg: {
      borderRadius: theme.shape.borderRadius_sm,
      padding: '8px 8px 4px 8px',
      marginRight: 10,
    },
    iconBgPrimary: {
      background: theme.palette.primary.main,
    },
    iconBgSecondary: {
      background: theme.palette.secondary.main,
    },
    relativeContainer: {
      position: 'relative',
    },
    legend: {
      position: 'absolute',
      display: 'block',
      zIndex: 10,
      top: 20,
      right: 20,
      padding: 10,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.paper,
    },
    legendBar: {
      width: 160,
      height: 12,
      marginBottom: 8,
      borderRadius: theme.shape.borderRadius,
      background: `linear-gradient(to right, ${mapColors.gradient.start} , ${mapColors.gradient.end})`,
    },
    legendBarText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignRight: {
      textAlign: 'right',
    },
  })),
)(TrackableContainersDetails);
