import { TrackerDevice } from 'cloudsort-client';
import { LatLngMapboxAlike } from './types';

//Calculate color having two colors and percentage -- HEX values
const interpolateColor = (
  color1: string,
  color2: string,
  percentage: number,
) => {
  const color1Map = color1
    .slice(1)
    .match(/.{1,2}/g)
    ?.map((oct) => parseInt(oct, 16) * (1 - percentage));
  const color2Map = color2
    .slice(1)
    .match(/.{1,2}/g)
    ?.map((oct) => parseInt(oct, 16) * percentage);
  let ci = [0, 1, 2].map((i) =>
    Math.min(Math.round(color1Map![i]! + color2Map![i]), 255),
  );
  return ci
    .reduce((a, v) => (a << 8) + v, 0)
    .toString(16)
    .padStart(6, '0');
};

const renderBatteryPercentageLabel = (device?: TrackerDevice) => {
  if (device?.battery_exact)
    return `${(Number(device.battery_exact) * 100).toFixed()}%`;

  if (device?.battery_range_lower && device?.battery_range_upper)
    return `${(
      Number(device.battery_range_lower) * 100
    ).toFixed()}-${(
      Number(device.battery_range_upper) * 100
    ).toFixed()}%`;

  return 'N/A';
};

const renderTemperatureLabel = (device?: TrackerDevice) => {
  if (device?.temperature_exact)
    return `${Number(device.temperature_exact).toFixed(1)} ºC`;

  if (
    device?.temperature_range_lower &&
    device?.temperature_range_upper
  )
    return `${Number(device.temperature_range_lower).toFixed(
      1,
    )}-${Number(device.temperature_range_upper).toFixed(1)} ºC`;

  return 'N/A';
};

const renderLocalTimestamp = (dateTime: string) => {
  return new Date(dateTime).toLocaleString();
};

const renderLocalTimezone = () => {
  const offset = new Date().getTimezoneOffset();

  const offsetHours = -offset / 60;

  if (offsetHours === 0) return 'UTC';

  return `UTC${offsetHours > 0 ? '+' : '-'}${offsetHours}`;
};

const getNormalizedBounds = (
  bounds: LatLngMapboxAlike,
): LatLngMapboxAlike => {
  const normalizeLng = (lng: number) =>
    lng < -180 ? -180 : lng > 180 ? 180 : lng;

  const normalizeLat = (lat: number) =>
    lat < -90 ? -90 : lat > 90 ? 90 : lat;

  return {
    _sw: {
      lng: normalizeLng(bounds._sw.lng),
      lat: normalizeLat(bounds._sw.lat),
    },
    _ne: {
      lng: normalizeLng(bounds._ne.lng),
      lat: normalizeLat(bounds._ne.lat),
    },
  };
};

export {
  interpolateColor,
  renderBatteryPercentageLabel,
  renderTemperatureLabel,
  renderLocalTimestamp,
  renderLocalTimezone,
  getNormalizedBounds,
};
